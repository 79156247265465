<template>
  <div class="ccc">
    <TopHead></TopHead>
    <logoNav></logoNav>
    <div class="uc_con">

      <div class="uc_title d_f ali_c flex_1">
        <div class="uc_green"></div>
        <div>账户余额</div>
      </div>
      <div class="fn_mon d_f ali_c">
        <div class="flex_1"></div>
        <div class="d_f ali_b">
          <span>账户余额：</span>
          <span class="red_text">{{balance ? balance/100 : 0}}</span>
        </div>
        <div class="ch_btn">
          <div class="fn_btn cur_p" @click="czPop = true">充值</div>
          <div class="fn_btn ac" v-if="balance" @click="$router.push('/cashing')">提现</div>
        </div>
      </div>

      <div class="t_l">
        <el-form :inline="true" :model="pageInfo.data" class="demo-form-inline">
          <el-form-item label="操作时间">
            <el-date-picker
              v-model="value1"
              value-format="YYYY-MM-DD"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item label="关联订单号">
            <el-input placeholder="关联订单号" v-model="pageInfo.data.bizId"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="resetSearch">重置</el-button>
            <el-button type="primary" @click="search">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <el-table
        ref="multipleTable"
        :data="list"
        stripe
        :header-cell-style="{'text-align':'center','background-color': '#F4F4F4','font-size': '14px','color': '#333','font-weight': 'bold'}"
        tooltip-effect="dark"
        style="width: 100%"
      >
        <el-table-column label="分销商名称" align="center">
          <template #default="scope">{{ scope.row.businessName }}</template>
        </el-table-column>
        <el-table-column prop="originalAmount" label="原账户余额" align="center">
          <template #default="scope">{{ scope.row.originalAmount ? scope.row.originalAmount/100 : 0 }}</template>
        </el-table-column>
        <el-table-column prop="amount" label="当前变动金额" align="center" show-overflow-tooltip>

          <template #default="scope">{{ scope.row.amount ? scope.row.amount/100 : 0 }}</template>
        </el-table-column>
        <el-table-column prop="currentBalance" label="当前账户余额" align="center" show-overflow-tooltip>

          <template #default="scope">{{ scope.row.currentBalance ? scope.row.currentBalance/100 : 0 }}</template>
        </el-table-column>
        <el-table-column prop="changeItem" label="账户资金变动原因" align="center" show-overflow-tooltip>
          <template #default="scope">
            <span v-if="scope.row.changeItem == 1">购票</span>
            <span v-if="scope.row.changeItem == 2">退票</span>
            <span v-if="scope.row.changeItem == 3">充值</span>
            <span v-if="scope.row.changeItem == 4">提现</span>
            <span v-if="scope.row.changeItem == 5">还款</span>
          </template>
        </el-table-column>
        <el-table-column prop="orderNumber" label="关联订单号" align="center" show-overflow-tooltip></el-table-column>
        <el-table-column prop="creatorTime" label="操作时间" align="center" show-overflow-tooltip></el-table-column>
      </el-table>
      <div class="t_r mt_12">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          v-model:currentPage="pageInfo.pageIndex"
          :page-sizes="$global.pageArr"
          :page-size="pageInfo.pageSize"
          layout="sizes, prev, pager, next"
          :total="total"
        ></el-pagination>
      </div>
    </div>


    <!--充值弹窗-->
    <el-dialog
      v-model="czPop"
      width="25%"
      :show-close="false"
    >
      <template #title>
        <div class="uc_title d_f ali_c flex_1">
          <div class="uc_green"></div>
          <div>请输入金额(元)</div>
          <div class="flex_1 t_r" @click="czPop = false">
            <img class="close" src="../assets/images/bk_close.png" alt="">
          </div>
        </div>
      </template>
      <div class="td_pop_con t_l">
        <el-input v-model="czAmount"></el-input>
      </div>
      <template #footer>

        <div class="t_a ch_btn td d_f">
          <div class="cp_btn"  @click="goPay">确定</div>
          <div class="cp_btn ac" @click="czPop = false">取消</div>
        </div>
      </template>
    </el-dialog>


    <!--二维码弹窗-->
    <el-dialog
      v-model="qrcodeShow"
      :show-close="false"
      width="30%"
    >
      <template #title>
        <div class="uc_title d_f ali_c flex_1">
          <div class="uc_green"></div>
          <div>请扫码支付</div>
          <div class="flex_1 t_r" @click="qrcodeShow = false">
            <img class="close" src="../assets/images/bk_close.png" alt="">
          </div>
        </div>
      </template>
      <div class="td_pop_con t_a">
        <div ref="qrCodeUrl" id="qrCodeUrl"></div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
  import QRCode from 'qrcodejs2'
  export default {
    name: "finance",
    data() {
      return {
        value1:'',
        searchInfo:{},
        list:[],
        balance:0,
        total:0,
        pageInfo:{
          pageIndex:1,
          pageSize:10,
          data:{'changeBizType':'1','bizId':'','beginTime':'','endTime':''}
        },
        czPop:false,
        czAmount:0,
        qrcodeUrl:'',
        payInfoId:'',
        qrcodeShow:false,
        interval:null,
      }
    },
    watch:{
      qrcodeShow(n,o){
        if(!n){
          clearInterval(this.interval);
          this.interval = null;
        }
      }
    },
    created() {
      this.getBusinessWallet();
      this.search();
    },
    methods: {
      delPay(){
        clearInterval(this.interval);
        this.interval = null;
        this.qrcodeShow = false;
        this.czPop = false;
        this.getBusinessWallet();
        this.search();
      },
      queryPayInfo(){
        this.$api.queryPayInfo({payInfoId:this.payInfoId}).then(res => {
          let sta = res.data.kybQueryResp.tradeState;
          if(sta !== '0'){
            if(sta === '1'){
              this.$message.success('支付成功');
              this.delPay();
            }else{
              this.$message.error(res.data.kybQueryResp.errMsg);
              this.delPay()
            }
          }
        })
      },
      goPay(){
        this.$api.createPayInfoFinance({"amount": this.czAmount*100, "payType": 2,}).then(res => {
          this.qrcodeUrl = res.data.qrcodeUrl;
          this.payInfoId = res.data.payInfoId;
          this.qrcodeShow = true;
          setTimeout(() => {
            this.creatQrCode(res.data.qrcodeUrl)
          },500)
        })
      },
      creatQrCode(url) {
        var qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: url, // 需要转换为二维码的内容
          width: 200,
          height: 200,
          colorDark: '#000000',
          colorLight: '#ffffff',
          correctLevel: QRCode.CorrectLevel.H
        });
        this.interval = setInterval(() => {
          this.queryPayInfo()
        },1000)
      },
      resetSearch(){
        this.value1 = '';
        this.pageInfo.data.bizId = '';
        this.pageInfo.data.beginTime = '';
        this.pageInfo.data.endTime = '';
      },
      search(){
        if(this.value1.length){
          this.pageInfo.data.beginTime = this.value1[0];
          this.pageInfo.data.endTime = this.value1[1];
        }
        this.$api.queryPageFi(this.pageInfo).then(res => {
          this.total = res.data.recordCount;
          this.list = res.data.rows;
        })
      },
      handleSizeChange(val) {
        this.pageInfo.pageSize = val;
        this.pageInfo.pageIndex = 1;
        this.search();
      },
      handleCurrentChange(val) {
        this.pageInfo.pageIndex = val;
        this.search();
      },
      //查询账户余额
      getBusinessWallet(){
        this.$api.getBusinessWallet().then(res => {
          this.balance = res.data.balance;
        })
      },
    }
  }
</script>

<style scoped lang="scss">
  #qrCodeUrl{
    :v-deep  img{
      display: inline-block!important;
    }
  }
  .fn_mon{
    .ch_btn div{
      width: 112px;
      height: 65px;
      line-height: 63px;
      border-radius: 6px;
      font-size: 22px;
      margin-left: 27px;
      cursor: pointer;
      display: inline-block;
    }
    .red_text{
      font-size: 42px;
      font-weight: bold;
      color: #FF2626;
    }
    margin-bottom: 20px;
    font-size: 20px;
    justify-items: right;
    border-bottom: 7px solid #F0F1F3;
    padding-bottom: 15px;
  }

</style>
